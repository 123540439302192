import { Controller } from "stimulus";

export default class extends Controller {

  editCesantiasEntity(e) {
    e.preventDefault();

    const edit_form = document.getElementById(`edit-cesantias-${e.target.dataset.id}-entity-form`);
    const add_form = document.getElementById(`add-cesantias-${e.target.dataset.id}-entity-form`);
    const cesantia_entity_label = document.getElementById(`cesantias-${e.target.dataset.id}-entity-label`);
    const pay_cesantias_button = document.getElementById("pay-cesantias-button");

    edit_form.classList.remove("is-hidden");

    add_form.classList.add("is-hidden");
    cesantia_entity_label.classList.add("is-hidden");
    pay_cesantias_button.classList.add("is-hidden");
  }
}